'use strict';

const { event } = require("jquery");

// Constructor
var Header = function() {
    var header = $('.header');
    var body = $('body');
    var menuOpen = $('.header__hamburguer');
    var links = $('.header__menu a');

    links.on('click', function() {
      header.removeClass('-open');
      body.removeClass('-hideOverflow');
    });

    menuOpen.on('click', function(){
      header.toggleClass('-open');
      body.toggleClass('-hideOverflow');
    });

    var tooltips = $('[data-tooltip]');

    tooltips.on('click mouseenter', function(e) {
      var currentTooltip = $(this);
      var tooltipText = currentTooltip.data('tooltip');
      var tooltipEl = $("<span></span>").text(tooltipText);
      currentTooltip.append(tooltipEl);
      currentTooltip.addClass('js-tooltip-shown');
    });

    tooltips.on('click mouseleave', function(e) {
      
      var currentTooltip = $(this);

      if(currentTooltip.hasClass('js-tooltip-shown')) {
        currentTooltip.find('span').remove();
        currentTooltip.removeClass('js-tooltip-shown');
      }
    });
};

module.exports = Header;
